// Importing global scss variables
@import "./app/core/theme/se_theme";
// @import url("https:fonts.googleapis.com/icon?family=Material+Icons");
@import "./app/core/theme/components/se_loader";

::-webkit-scrollbar-track {
  border: 5px solid white;
  background-color: #b2bec3;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
  background-color: #6b72794b;
  border-radius: 10px;
}

button:focus {
  outline: 1px double;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
}
body main {
  min-height: 82vh;
  margin: 0 10px 10px 10px;
}

.pointer {
  cursor: pointer;
}

// status classes
@import "./app/core/theme/se_variables";

.cursor {
  cursor: pointer;
}
.open {
  color: $se-orange-1;
}
.bg-open {
  background-color: $se-gray-2 !important;
}
.pending {
  color: $se-color-info;
  margin: 10px;
}
.success {
  color: $se-color-success;
  margin: 10px;
}
.bg-success {
  background-color: $se-color-success !important;
}
.bg-approved {
  background-color: $se-color-approved !important;
}
.success-below-threshold {
  color: $se-yellow-3;
  margin: 10px;
}
.bg-success-below-threshold {
  background-color: $se-yellow-3 !important;
}
.error {
  color: $se-color-error;
  margin: 10px;
}
.bg-error {
  background-color: $se-color-error !important;
}
.light_error {
  color: $se-gray-5;
  margin: 10px;
}

.left {
  float: left;
}

.right {
  float: right;
}

:focus-visible {
  outline-style: dashed;
  outline-width: 2px;
}

.p-progressbar-value {
  background: $se-bright-purple !important;
}

.p-datatable .p-datatable-thead > tr > th {
  font-size: 14px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  font-size: 14px;
}

.pki-login-siemens {
  background: $se-purple !important;
  color: white !important;
}

#okta-sign-in.auth-container .button-primary {
  background: white !important;
  color: black !important;
}





.meta-container {
  width: 500px;
  margin: 20px;

}

 .meta-content {
  height: 320px;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;

  .meta-fields {
    flex: 0 0 48%;
    max-width: 48%;
  }
}

::ng-deep .meta-content .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0px 0 8px 0;
}

::ng-deep .meta-content .de-input {
  margin: -5px 5px -15px 0px !important;
}

.meta-button {
  display: flex;
  justify-content: center;
  margin: 20px;
  height: 100px;
  align-items: end;
}

.user-filter-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .user-filter {
    padding: 0px 10px;
    line-height: 1.5rem;
    color: dimgray;
  }
}
