// Primary Colors
$se-purple: #641e8c;
$se-dark-purple: #1b1534;
$se-mid-purple: #4d217a;
$se-light-purple: #8a39b9;
$se-bright-purple: #8A00E5;
$se-purple-clicked: #3d0f4d;
$se-petrol: #009999;
$se-black: #000000;
$se-white: #ffffff;
$se-black-lighter: #495057;
$se-light-grey: #f8f9fa;
$se-gradient-purple: linear-gradient(to right, $se-dark-purple, $se-mid-purple);



// Secondary Colors
$se-brown-0: #efebea;
$se-brown-1: #cfc0be;
$se-brown-2: #af9e9c;
$se-brown-3: #8f7c7a;
$se-brown-4: #7d6766;
$se-brown-5: #564646;

$se-gray-0: #eaf4f8;
$se-gray-1: #d7e4ee;
$se-gray-2: #b8cedb;
$se-gray-3: #96b0c0;
$se-gray-4: #7b919d;
$se-gray-5: #54646e;
$se-gray-6: #e9ecef;

$se-green-0: #dcffdc;
$se-green-1: #00fd79;
$se-green-2: #14da79;
$se-green-3: #27b66d;
$se-green-4: #009b55;
$se-green-5: #006c3b;
$se-green-6: #006b39;

$se-blue-0: #caf9ff;
$se-blue-1: #6addff;
$se-blue-2: #21c0ff;
$se-blue-3: #0084e1;
$se-blue-4: #0057c6;
$se-blue-5: #00328c;
$se-blue-6: #337ab7;

$se-yellow-0: #fcfcb5;
$se-yellow-1: #fff91f;
$se-yellow-2: #f6e600;
$se-yellow-3: #e2d000;
$se-yellow-4: #c6ae00;
$se-yellow-5: #8c7500;
$se-input-edited: lightgoldenrodyellow;

$se-orange-0: #ffc993;
$se-orange-1: #fe8304;

// colors
$se-color-error: #f06f6f;
$se-color-info: #66a4f6;
$se-color-success: $se-green-3;
$se-color-approved: $se-green-6;
$se-color-light: #F8F8F8;
$se-color-mid-light: #F0F0F0;


// Inputs
$se-input-default: #5D596E;
$se-input-hover: #E6E6E6;
$se-input-hover-dark: #410D71;
$se-input-disabled: #ADA9C2;

// Size
$se-size-h1-lg: 60pt;
$se-size-h2-lg: 44pt;
$se-size-h3-lg: 36pt;
$se-size-h4-lg: 26pt;
$se-size-h5-lg: 18pt;
$se-size-h6-lg: 18pt;
$se-size-h1-md: 44pt;
$se-size-h2-md: 36pt;
$se-size-h3-md: 26pt;
$se-size-h4-md: 22pt;
$se-size-h5-md: 18pt;
$se-size-h6-md: 18pt;
$se-size-h1-sm: 32pt;
$se-size-h2-sm: 23pt;
$se-size-h3-sm: 22pt;
$se-size-h4-sm: 20pt;
$se-size-h5-sm: 18pt;
$se-size-h6-sm: 18pt;

$se-size-btn-text: 18px;
$se-btn-radius: 23px;
$se-size-nav-text: 16px;



$button-color: $se-purple;